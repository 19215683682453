import React from 'react'

const Photographs = () => {
  return (
    <>
        Photographs
    </>
  )
}

export default Photographs
