import { Box, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import url from "../../url";

const SingleGalleryPost = () => {
   const [galleryPosts, setGalleryPosts] = useState([]);
   const { postIndex } = useParams();

   const getAllGalleryPosts = async () => {
      try {
         const res = await axios.get(`${url}/api/v1/gallery/allposts`);
         //  console.log(res);

         setGalleryPosts(res.data.gallery);
      } catch (error) {
         console.log(error);
      }
   };
   useEffect(() => {
      getAllGalleryPosts();
   }, []);

   console.log(galleryPosts[postIndex]?.postTitle);

   return (
      <Box p={2}>
         <Typography my={2} variant="h3" textAlign={"center"}>
            {galleryPosts[postIndex]?.postTitle}
         </Typography>
         <Typography>{galleryPosts[postIndex]?.postDesc}</Typography>
         {galleryPosts[postIndex]?.postImage.length !== 0 &&
            galleryPosts[postIndex]?.postImage?.map((item, i) => {
               return (
                  <Box
                     component={"img"}
                     src={`${url}/api/v1/gallery/get-gallery-img?name=${item}`}
                     width={"100%"}
                     sx={{
                        my: "14px",
                     }}
                  />
               );
            })}
         {galleryPosts[postIndex]?.postVideo.length !== 0 &&
            galleryPosts[postIndex]?.postVideo?.map((item, i) => {
               return (
                  <video width="100%" controls>
                     <source src={`${url}/api/v1/gallery/get-gallery-img?name=${item}`} />
                  </video>
               );
            })}
      </Box>
   );
};

export default SingleGalleryPost;
