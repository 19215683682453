exports.data=[
  
      {
        name:'shiva',
        age:'24',
        number:'73185677765'
      },
  
    
      {
        name:'shivam',
        age:'25',
        number:'9145546050'
      },
  
   {
        name:'alok',
        age:'44',
        number:'7566778546050'
      },
  
   {
        name:'vik',
        age:'74',
        number:'7318546000050'
      
    },
    {
        name:'abhi',
        age:'23',
        number:'7318546050'
      },
    
    {
        name:'shivam',
        age:'24',
        number:'6458546050'
      },
 
  {
        name:'shivam',
        age:'27',
        number:'739766050'
      },
    
    ]
